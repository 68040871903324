import {
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ActivatedRoute,
  EventType,
  Router,
  RouterModule,
} from '@angular/router';
import {
  DialogService,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { filter, Subject } from 'rxjs';
import { PageRegisterComponent } from '../../../amm-pages/page-register/page-register.component';
import { MATERIAL_ICON_NAME } from '../../constants';
import {
  AuthService,
  GoogleApiService,
  ProductService,
  RbacService,
  StorageService,
} from '../../services';

import { AuthStore, ShoppingInfoStore } from '../../stores';
import { AmmButtonWithBageComponent } from '../amm-button-with-bage/amm-button-with-bage.component';
import { GlobalSearchComponent } from '../global-search/global-search.component';
@Component({
  selector: 'amm-global-nav-bar',
  standalone: true,
  imports: [
    RouterModule,
    AmmButtonWithBageComponent,
    OverlayPanelModule,
    DynamicDialogModule,
    PageRegisterComponent,
    GlobalSearchComponent,
  ],
  templateUrl: './amm-global-nav-bar.component.html',
  styleUrl: './amm-global-nav-bar.component.scss',
  providers: [],
})
export class AmmGlobalNavBarComponent implements OnInit {
  @Input()
  showFilterButton?: boolean;
  @Input()
  showFilterPanel?: boolean;
  shouldExploreBtnActiveStyle: boolean = false;
  @Output()
  searchKey = new EventEmitter<string>();
  @Output()
  showFilter = new EventEmitter<boolean>();
  public iconName = MATERIAL_ICON_NAME;

  public shopInfoStore = inject(ShoppingInfoStore);
  public authStore = inject(AuthStore);
  public authService = inject(AuthService);
  private dialogService = inject(DialogService);
  private _storageService = inject(StorageService);
  public productService: ProductService = inject(ProductService);
  public googleLoginService = inject(GoogleApiService);
  public _rbacService = inject(RbacService);
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private ref: DynamicDialogRef | undefined;
  private _router: Router = inject(Router);
  public isFavoritePage: boolean = false;
  public isOrdersPage: boolean = false;
  public isShoppingCartPage: boolean = false;
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this._router.events
      .pipe(filter((x) => x.type === EventType.NavigationEnd))
      .subscribe((x) => {
        this.isFavoritePage = false;
        this.isOrdersPage = false;
        this.isShoppingCartPage = false;
        if (this._router.url.includes('favorites')) {
          this.isFavoritePage = true;
          this.shouldExploreBtnActiveStyle = false;
        } else if (this._router.url.includes('shopping-carts')) {
          this.isShoppingCartPage = true;
          this.shouldExploreBtnActiveStyle = false;
        } else if (this._router.url.includes('orders')) {
          this.isOrdersPage = true;
          this.shouldExploreBtnActiveStyle = false;
        } else {
          this.shouldExploreBtnActiveStyle = true;
        }
      });
  }

  ngAfterViewInit() {
    var _fragment: any = null;
    this.route.fragment.subscribe((fragment) => {
      _fragment = fragment;
    });

    if (_fragment) {
      // const params = new URLSearchParams(_fragment);

      // const state = params.get('state');
      // const access_token = params.get('access_token');
      // const token_type = params.get('token_type');
      // const expires_in = params.get('expires_in');
      // const scope = params.get('scope');
      // const id_token = params.get('id_token');
      // const authuser = params.get('authuser');
      // const prompt = params.get('prompt');

      // console.log('state:', state);
      // console.log('access_token:', access_token);
      // console.log('token_type:', token_type);
      // console.log('expires_in:', expires_in);
      // console.log('scope:', scope);
      // console.log('id_token:', id_token);
      // console.log('authuser:', authuser);
      // console.log('prompt:', prompt);

      this.googleLoginService.initGoogleAPI();
    }
  }

  isLoggedIn() {
    var isLoggedIn = this.authService.isLoggedIn();
    return isLoggedIn;
  }

  ngOnDestroy() {
    if (this.ref) {
      this.ref.close();
    }
  }
  onButtonClick(event: string) {
    switch (event) {
      case MATERIAL_ICON_NAME.favorite:
        this._router.navigate([
          'home',
          { outlets: { mainBody: ['favorites'] } },
        ]);
        break;
      case MATERIAL_ICON_NAME.shoppingCart:
        this._router.navigate([
          'home',
          { outlets: { mainBody: ['shopping-carts'] } },
        ]);
        break;
      case MATERIAL_ICON_NAME.orders:
        this._router.navigate(['home', { outlets: { mainBody: ['orders'] } }]);
        break;
    }
  }

  onLogout() {
    this._rbacService.logout().subscribe((user) => {
      console.log('=== ammall logged out ===');
    });
    this._storageService.removeJWT();
    window.location.reload();
  }

  onLogin() {
    this.dialogService.open(PageRegisterComponent, {
      showHeader: false,
      // closable:true,
      width: '760px',
      style: {
        padding: '0px',
        'border-radius': '24px',
        width: '760px',
        'min-height': '532px',
      },
      contentStyle: {
        overflow: 'visible',
        padding: '0px',
        'border-radius': '24px',
        width: '760px',
        'min-height': '532px',
      },
      breakpoints: {
        '760px': '95vw',
      },
    });
  }
  onExplore() {
    this._router.navigate(['home', { outlets: { mainBody: null } }]);
    console.log(this._router.url);
    if (typeof window != 'undefined' && this._router.url === '/') {
      window.location.reload();
    }
  }
}
