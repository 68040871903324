<div class="flex justify-between global-nav-wrapper">
  <div class="flex items-center gap-6 logo-container">
    <a class="cursor-pointer" (click)="onExplore()">
      <img
        class="brand-logo"
        src="../../../../assets/img/logo/logo_complete_horizontal.png"
        alt="logo"
        aria-hidden="true"
      />
    </a>
    <button
      [class.primary-button-active]="shouldExploreBtnActiveStyle"
      type="button"
      (click)="onExplore()"
    >
      Explore
    </button>
  </div>
  <!-- Global Search  -->
  <amm-global-search class="w-full flex justify-center"></amm-global-search>

  <!-- Function Section -->
  <div class="flex items-center gap-8 p-2 function-container">
    <div class="flex">
      <amm-button-with-bage
        [iconName]="iconName.favorite"
        iconDesciption="favorites"
        [isBtnActive]="isFavoritePage"
        (onButtonClick)="onButtonClick($event)"
      ></amm-button-with-bage>
      <amm-button-with-bage
        [iconName]="iconName.orders"
        iconDesciption="orders"
        [isBtnActive]="isOrdersPage"
        (onButtonClick)="onButtonClick($event)"
      ></amm-button-with-bage>
      <amm-button-with-bage
        [iconName]="iconName.shoppingCart"
        iconDesciption="carts"
        [isBtnActive]="isShoppingCartPage"
        [counter]="shopInfoStore.shoppingCartCount()"
        (onButtonClick)="onButtonClick($event)"
      ></amm-button-with-bage>
    </div>
    <!-- Avatar Section/Login Section -->
    @if (authService.isLoggedIn()) {
      <!-- TODO: This need to be finalized with UI design team -->
      <div
        class="flex flex-row items-center gap-1 profile-img-warpper cursor-pointer"
        (click)="op.toggle($event)"
      >
        <img
          #panelHolder
          class="avatar-img"
          src="../../../../assets/img/avatar.png"
          alt=""
        />
        <span class="material-symbols-outlined">keyboard_arrow_down</span>
        <p-overlayPanel #op>
          <div
            class="flex flex-col justify-start p-2 gap-2 text-2xl min-h-96 min-w-96"
          >
            <button
              class="button-action-primary"
              type="button"
              (click)="onLogout()"
            >
              Log out
            </button>
          </div>
        </p-overlayPanel>
      </div>
    } @else {
      <button class="button-action-primary" type="button" (click)="onLogin()">
        Log in
      </button>
    }
  </div>
</div>
