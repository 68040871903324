import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { Product } from '../../models/catalog.model';
import { ProductService } from '../../services';

@Component({
  selector: 'amm-search-recommand',
  standalone: true,
  imports: [],
  templateUrl: './search-recommand.component.html',
  styleUrl: './search-recommand.component.scss',
})
export class SearchRecommandComponent implements OnInit, OnDestroy {
  public recommendProd: Array<Product> = [];
  private productService = inject(ProductService);
  private destory$: Subject<boolean> = new Subject<boolean>();
  private router: Router = inject(Router);
  ngOnInit(): void {
    this.productService
      .getProducts('com', '', 0, 4)
      .pipe(takeUntil(this.destory$))
      .subscribe((item) => {
        this.recommendProd = item.dataList || [];
      });
  }
  ngOnDestroy(): void {
    this.destory$.next(true);
    this.destory$.complete();
  }
  gotoThisProduct(p: Product): void {
    this.router.navigate([
      'home',
      {
        outlets: {
          mainBody: [
            'details',
            (p.variantMerchs?.length || 0) > 0 ? p.code : p.id,
          ],
        },
      },
    ]);
  }
}
